import { createSlice } from "@reduxjs/toolkit";
import { SharedState } from "../state.model";

const initialState: SharedState = {
  token: null,
  user: null,
  loading: false,
  event: null
};

const sharedReducer = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setEvent(state, action) {
      state.event = action.payload;
    }
  },
});

export const { setToken, setUser, setLoading, setEvent } = sharedReducer.actions;

export default sharedReducer.reducer;
