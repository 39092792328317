
import {
    AccountInfo,
    PublicClientApplication
} from '@azure/msal-browser'
import { config } from '../configs/auth';
import { setToken, setUser } from '../store/reducers/sharedReducer';

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.authentication.clientId,
    authority: config.authentication.authority ,
    knownAuthorities: config.authentication.knownAuthorities,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

export const asyncLogin = ()  => async (dispatch: any) => { 
  await publicClientApplication.initialize();
  let tokenResponse
  try {
    tokenResponse = await publicClientApplication.handleRedirectPromise();
  } catch (e) {
    await publicClientApplication.loginRedirect({
      scopes: config.authentication.scopes,
    });
  }

  let account: AccountInfo| null;
  if (tokenResponse) {
    account = tokenResponse.account;
  } else {
    account = publicClientApplication.getAllAccounts()[0];
  }
  
  if(!account){
    await publicClientApplication.loginRedirect({
      scopes: config.authentication.scopes,
    });
  }else{    
    try {
      const token = await publicClientApplication.acquireTokenSilent({
        account: account,
        scopes: config.authentication.scopes,
      });
      const {name, username, idTokenClaims} = account;
      dispatch(setUser({name, username, roles: idTokenClaims?.roles, testRoles: idTokenClaims?.testRoles, groups: idTokenClaims?.groups}));
      dispatch(setToken(token.accessToken));
    } catch (e) {
      await publicClientApplication.loginRedirect({
        scopes: config.authentication.scopes,
      });
    }
  }
};



export const logout = async () => {
  try {
    await publicClientApplication.logoutRedirect();
  } catch (e) {
  }
};