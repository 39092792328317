import { apiConfig } from './../configs/api';
import { store } from '../store/store';
import { asyncLogin } from './authService';

export enum HttpMethods {
  GET,
  POST,
  PUT,
  DELETE,
}

async function request(
  url: string,
  method: HttpMethods,
  payload: any | null = null,
  ignoreToJson: boolean = false,
  isFileUpload: boolean = false
): Promise<any> {
  try {
    var httpMethod = HttpMethods[method];

    const requestHeaders: HeadersInit = new Headers();
    if(!isFileUpload)
    {
      requestHeaders.set('Content-Type', 'application/json');
    }
    requestHeaders.set('Authorization', `Bearer ${store.getState().shared.token}`);

    let requestOptions = {
      method: httpMethod,
      headers: requestHeaders,
      body: payload !== null ? (isFileUpload ? payload : JSON.stringify(payload)) : null,
    };


    const response = await fetch(url, requestOptions);
    if (response.status !== 200) {
      if (response.status === 401) {
        asyncLogin();
      }
      console.error(response);
      throw new Error(`Request failed with status code ${response.status}`);
    }

    if(ignoreToJson)
      return response;

    return response.json();
  } catch (e) {
    throw new Error(`Failed to fetch from ${url}: ${e}`);
  }
}

export const apiService = {
  async getTeams(): Promise<any> {
    const url = `${apiConfig.url}/Teams`;
    return request(url, HttpMethods.GET, null);
  },
  async getAppointmentTypes(): Promise<any> {
    const url = `${apiConfig.url}/AppointmentTypes`;
    return request(url, HttpMethods.GET, null);
  },
  async getAppointments(query: string): Promise<any> {
    const url = `${apiConfig.url}/AppointmentsManager?${query}`;
    return request(url, HttpMethods.GET, null, true);
  },
  async uploadAppointments(body: any): Promise<any> {
    const url = `${apiConfig.url}/AppointmentBulkUpload`;
    return request(url, HttpMethods.POST, body, true, true);
  },
  async updateAppointment(id: string, body: any): Promise<any> {
    const url = `${apiConfig.url}/AppointmentsManager/${id}`;
    return request(url, HttpMethods.POST, body);
  },
  async deleteAppointment(id: string): Promise<any> {
    const url = `${apiConfig.url}/AppointmentsManager/${id}`;
    return request(url, HttpMethods.DELETE, null, true);
  },
  async uploadFile(container: string, body: any): Promise<any> {
    const url = `${apiConfig.url}/Files?container=${container}`;
    return request(url, HttpMethods.POST, body, false, true);
  },
};
