export enum ApiActions{
    AppointmentUpdated,
    AppointmentDeleted,
    AppointmentUploaded,
    AppointmentSearch,
    OnError
}

export enum ApiEventType{
    Success = "success",
    Error = "error"
}

export interface ApiEvent{
    type: ApiEventType,
    action: ApiActions;
    message: string;
    data: any;
}