import { createSlice } from "@reduxjs/toolkit";
import { AppointmentState } from "../state.model";

const initialState: AppointmentState = {
  list: [],
  gridSource: [],
  tableParam: {
    pagination: {
      current: 1,
      pageSize: 15
    }
  },
  query: {
    customer: "",
    orderNumber: "",
    team: "",
    appointmentType: "",
    appointmentDate: "",
    appointmentEndDate: "",
    pageNumber: 1,
    pageSize: 15
  },
  teams: [],
  appointmentTypes: [],
  uploadedFiles: []
};

const appointmentReducer = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setAppointments(state, action) {
      state.list = action.payload;
      state.gridSource = action.payload;
    },
    setAppointmentSource(state, action) {
      state.gridSource = action.payload;
    },
    setTableParams(state, action) {
      state.tableParam = action.payload;
    },
    setPaginationQuery(state, action) {
      state.query.pageNumber = action.payload.pageNumber;
      state.query.pageSize = action.payload.pageSize;
    },
    setCustomerQuery(state, action) {
      state.query.customer = action.payload;
    },
    setOrderNoQuery(state, action) {
      state.query.orderNumber = action.payload;
    },
    setTeamQuery(state, action) {
      state.query.team = action.payload;
    },
    setAppTypeQuery(state, action) {
      state.query.appointmentType = action.payload;
    },
    setAppDateQuery(state, action) {
      state.query.appointmentDate = action.payload;
    },
    setAppEndDateQuery(state, action) {
      state.query.appointmentEndDate = action.payload;
    },
    setTeams(state, action) {
      state.teams = action.payload;
    },
    setAppointmentTypes(state, action) {
      state.appointmentTypes = action.payload;
    },
    setUploadedFiles(state, action){
      state.uploadedFiles = action.payload;
    }
  },
});

export const { setAppointments, setAppointmentSource, setTableParams, setPaginationQuery, setCustomerQuery, setTeams, setAppointmentTypes, setOrderNoQuery, setTeamQuery, setAppDateQuery, setAppEndDateQuery, setAppTypeQuery, setUploadedFiles } = appointmentReducer.actions;

export default appointmentReducer.reducer;
