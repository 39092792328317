import { Button, Upload, UploadFile, UploadProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { uploadAppointments } from "../../../services/dataService";
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getSharedState } from "../../../store/selectors/stateSelector";
import { apiConfig } from './../../../configs/api';

export const BulkUploadAppointments = (props: any) => {

    const dispatch = useDispatch();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { loading } = useSelector(getSharedState);

    const downloadTemplate = () => {
        window.open(apiConfig.bulkUploadFileTemplateUrl);
    };

    const uploadProps: UploadProps = {
      accept: ".xlsx",
      onRemove: (file) => {
        setFileList([]);
      },
      beforeUpload: (file) => {
        setFileList([file]);
        return false;
      },
      fileList,
    };
  
    const handleUpload = () => {
        dispatch<any>(uploadAppointments(fileList[0]));
        setFileList([]);
    };

    return (
        <>
            <Button icon={<DownloadOutlined />} onClick={downloadTemplate}>
                Template
            </Button>
            <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={loading}
            >
                Start Upload
            </Button>
        </>
    )
};
