import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import AuthGuard from './components/auth/authGuard';
import { store } from './store/store';
import { Role } from './models/role';
import Main from './components/layout/main';
import AccessDenied from './views/auth/accessDenied';
import AppoointmentsList from './views/appointments';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthGuard roles={[Role.Admin, Role.SuperAdmin]}>
        <Main>
          <Routes>
            <Route path='/' element={<Navigate to='/appointments' />} />
            <Route path='/appointments' element={<AppoointmentsList />} />
            <Route path='/401' element={<AccessDenied />} />
          </Routes>
        </Main>
      </AuthGuard>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
