import React from 'react';
import { Dropdown, Menu, Space, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getSharedState } from '../../store/selectors/stateSelector';
import { logout } from '../../services/authService';
import './../../styles/header.scss';

const Header: React.FC = () => {

  const { user } = useSelector(getSharedState);

  const { Text } = Typography;

  const onLogout = () => {
    logout();
  };

  const userAccountMenu = (
    <Menu>
      <div
        style={{ padding: '8px', alignContent: 'center' }}
        key='acc-user-name'
      >
        <Text strong>{user?.name}</Text>
        <br />
        <Text type='secondary'>{user?.username}</Text>
      </div>
      <Menu.Item
        icon={<LogoutOutlined />}
        key='acc-user-logout'
        onClick={onLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <PageHeader
      className='appHeader'
      extra={
        <Space size='middle' align='center'>
          <Dropdown
            overlay={userAccountMenu}
            placement='bottomRight'
            trigger={['click']}
            key='dropdown-user'
          >
            <UserOutlined />
          </Dropdown>
        </Space>
      }
      title={
        <Text strong className='title'>
          Appointment Manager
        </Text>
      }
      avatar={{ src: './logo512.png', shape: 'square' }}
    />
  );
};

export default Header;
