import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index"

const preloadedState = {};

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware(
    {
      serializableCheck: false
    }
  ),
  reducer: rootReducer(),
  preloadedState
});

