import { Button, Select, Table, TableColumnsType, TablePaginationConfig, Tooltip, DatePicker, Modal, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAppointment, getAppointmentTypes, getAppointments, getTeams } from "../../services/dataService";
import { Appointment } from "../../models/appointment";
import { getAppointmentState, getSharedState } from "../../store/selectors/stateSelector";
import { setAppDateQuery, setAppEndDateQuery, setAppTypeQuery, setCustomerQuery, setOrderNoQuery, setPaginationQuery, setTeamQuery } from "../../store/reducers/appointmentReducer";
import Search from "antd/lib/input/Search";
import { BulkUploadAppointments } from "./components/bulkUpload";
import { Content } from "antd/es/layout/layout";
import { EditAppointmentModal } from "./components/edit";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SearchEvent } from "../../models/searchEvent";
import dayjs from 'dayjs';
import { ApiActions } from "../../models/event";
import './styles.css';

const { confirm } = Modal;
const { Option } = Select;
const { RangePicker } = DatePicker;

const AppoointmentsList: React.FC = (props) => {
  const dispatch = useDispatch();

  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const { gridSource, tableParam, query, teams, appointmentTypes } = useSelector(getAppointmentState);
  const { event } = useSelector(getSharedState);
  const [selectedTeam] = useState('');
  const [selectedAppType] = useState('');

  useEffect(() => {
    dispatch<any>(getTeams());
    dispatch<any>(getAppointmentTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.pageSize, query.pageNumber, query.customer, query.team, query.orderNumber, query.appointmentType, query.appointmentDate, query.appointmentEndDate]);

  useEffect(() => {
    if (event != null) {
      if (event.action === ApiActions.AppointmentUpdated) {
        onToggleEditModal();
      }

      loadAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const loadAppointments = async () => {
    dispatch<any>(getAppointments(query, true));
  };

  const handleTableChange = (
    pagination: TablePaginationConfig
  ) => {
    let value =
    {
      pageNumber: pagination?.current ?? 1,
      pageSize: pagination?.pageSize ?? 15
    };
    dispatch<any>(setPaginationQuery(value));
  };

  const onSearchKeyUp = (event: any, key: SearchEvent) => {
    if (event && event.target && event.key === 13)
      onSearch(event.target.value, key);
  };

  const onSearch = (value: any, key: SearchEvent) => {

    if (value == undefined)
      value = "";

    if (key === SearchEvent.Customer)
      dispatch<any>(setCustomerQuery(value));

    if (key === SearchEvent.OrderNo)
      dispatch<any>(setOrderNoQuery(value));

    if (key === SearchEvent.Team)
      dispatch<any>(setTeamQuery(value));

    if (key === SearchEvent.AppointmentType)
      dispatch<any>(setAppTypeQuery(value));

    if (key === SearchEvent.AppointmentDates) {
      if (value && value[0] && value[1]) {
        const startDate = dayjs(value[0]);
        const endDate = dayjs(value[1]);
        dispatch<any>(setAppDateQuery(startDate));
        dispatch<any>(setAppEndDateQuery(dayjs(endDate)));
      }
      else {
        dispatch<any>(setAppDateQuery(""));
        dispatch<any>(setAppEndDateQuery(""));
      }

    }
  };

  const onToggleEditModal = () => setEditModalVisible((p) => !p);

  const onEdit = async (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    onToggleEditModal();
  };
  const onDelete = async (appointment: Appointment) => {

    confirm({
      title: 'Do you want to delete this appointment?',
      icon: <ExclamationCircleOutlined />,
      content: `${appointment.appointmentType.name} appointment for ${appointment.externalCaseId}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        console.log('Confirmed');
        dispatch<any>(deleteAppointment(appointment?.id!));
      },
    });

  };



  const columns: TableColumnsType<Appointment> = [
    {
      title: 'Order Number',
      dataIndex: 'externalCaseId',
      width: 150,
    },
    {
      title: 'Type',
      dataIndex: 'appointmentType.name',
      width: 130,
      render: (_, record) => {
        return (
          <span>
            {record?.appointmentType?.name}
          </span>
        );
      }
    }, {
      title: 'Customer',
      dataIndex: 'customer.name',
      width: 150,
      render: (_, record) => {
        return (
          <span>
            {record?.customer?.name}
          </span>
        );
      }
    },
    {
      title: 'Address',
      dataIndex: 'customerAddress.address1',
      width: 200,
      render: (_, record) => {
        return (
          <span>
            {record?.customerAddress?.address1}
          </span>
        );
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'appointmentDate',
      width: 200,
    },
    {
      title: 'End Date',
      dataIndex: 'appointmentEndDate',
      width: 200,
    },
    {
      title: 'Team',
      dataIndex: 'team.name',
      width: 200,
      render: (_, record) => {
        return (
          <span>
            {record?.team?.name}
          </span>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_, record) => {
        return (
          <span>
            <Tooltip placement='topLeft' title={`Edit`}>
              <Button
                shape='circle'
                icon={<EditOutlined />}
                size='small'
                onClick={() => onEdit(record)}
                key='edit'
              />
            </Tooltip>
            <Tooltip
              placement='topLeft'
              title={`Delete`}
            >
              <Button
                danger
                style={{
                  marginLeft: 8,
                }}
                shape='circle'
                icon={<DeleteOutlined />}
                size='small'
                onClick={() => onDelete(record)}
                disabled={record.status != 'NotStarted'}
              />
            </Tooltip>
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="filtersContainer">
        <Row gutter={[16, 16]}>
          <Col span={4} >
            <Search
              placeholder="Customer"
              onSearch={(val) => onSearch(val, SearchEvent.Customer)}
              onKeyUp={(val) => onSearchKeyUp(val, SearchEvent.Customer)}
              style={{ width: 300, marginRight: '16px' }}
            />
          </Col>
          <Col span={4} >
            <Search
              placeholder="Order Number"
              onSearch={(val) => onSearch(val, SearchEvent.OrderNo)}
              onKeyUp={(val) => onSearchKeyUp(val, SearchEvent.OrderNo)}
              style={{ width: 300, marginRight: '16px' }}
            />
          </Col>
          <Col span={4} >
            <Select
              optionFilterProp='children'
              showSearch
              allowClear
              placeholder='Search by Team'
              onChange={(val) => onSearch(val, SearchEvent.Team)}
              value={selectedTeam || undefined}
            >
              {teams.map((g) => (
                <Option key={g.id}>
                  {g.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={4} >
            <Select
              showSearch
              allowClear
              placeholder='Workorder type'
              optionFilterProp='children'
              onChange={(val) => onSearch(val, SearchEvent.AppointmentType)}
              value={selectedAppType || undefined}
            >
              {appointmentTypes.map((g) => (
                <Option key={g.id}>
                  {g.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6} >
            <RangePicker
              onChange={(val) => onSearch(val, SearchEvent.AppointmentDates)}
            />
          </Col>
        </Row>
      </div>

      <Content className="uploadContent">
        <BulkUploadAppointments />
      </Content>

      <Table
        dataSource={gridSource}
        columns={columns}
        rowKey={(record) => record.id}
        sticky={{ offsetHeader: 0.5 }}
        scroll={{ x: 'max-content' }}
        pagination={tableParam.pagination}
        onChange={handleTableChange}
      />

      <EditAppointmentModal
        show={editModalVisible}
        toggleShow={onToggleEditModal}
        formValue={selectedAppointment}
      />
    </>
  );

};

export default AppoointmentsList;
