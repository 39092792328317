export const config = {
    authentication: {
      clientId: '42eab713-24c8-45ab-95d6-cfdaa8d8d6e0',
      tenantId: 'd22da9a6-d89a-4764-9d56-96b020869dff',
      authority: `https://enpalcraftos.b2clogin.com/enpalcraftos.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_CRAFT`,
      knownAuthorities: ['enpalcraftos.b2clogin.com'],
      scopes: [
        'openid',
        'profile',
        'offline_access',
        'https://enpalcraftos.onmicrosoft.com/api/craftos/Forms.Read',
        'https://enpalcraftos.onmicrosoft.com/api/craftos/foo',
      ],
    }
  }