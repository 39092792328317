
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncLogin } from '../../services/authService';
import { getSharedState } from '../../store/selectors/stateSelector';

const AuthGuard = ({ children, roles }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, token  } = useSelector(getSharedState);
  
  useEffect(() => {
    dispatch<any>(asyncLogin());
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(token === null)
  {
    return;
  }
  const isAuthorized = user && user.testRoles.some((r) => roles.includes(r)) ? true : false;
  
  if (!isAuthorized && window.location.pathname !== '/401') {
    navigate('/401');
    return;
  }

  return children;
};

export default AuthGuard;
