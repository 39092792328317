import { Button, Col, DatePicker, Form, Input, List, Modal, Row, Select, Spin, Upload } from "antd";
import { getAppointmentState, getSharedState } from "../../../store/selectors/stateSelector";
import { useDispatch, useSelector } from "react-redux";
import { Appointment } from "../../../models/appointment";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import dayjs from 'dayjs';
import { AppointmentEditDto } from "../../../models/appointmentEditDto";
import { updateAppointment, uploadAppointmentFile } from "../../../services/dataService";
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { UploadedFile } from "../../../store/state.model";
import { setUploadedFiles } from "../../../store/reducers/appointmentReducer";

const { Option } = Select;

export interface EditAppointmentProps {
  show: boolean;
  toggleShow: any;
  formValue: Appointment | null;
}

export const EditAppointmentModal = (props: EditAppointmentProps) => {
  const dispatch = useDispatch();

  const { show, toggleShow, formValue } = props;
  const [form] = useForm();
  const { loading } = useSelector(getSharedState);
  const { teams, appointmentTypes, uploadedFiles } = useSelector(getAppointmentState);
  const [files, setFiles] = useState<UploadedFile[]>([]);

  useEffect(() => {
    setFileList([]);
    if (show) {
      form.resetFields();
      setFiles([]);
      if (formValue) {
        const value: AppointmentEditDto =
        {
          id: formValue.id,
          orderNo: formValue.externalCaseId,
          workOrderType: formValue.appointmentType.name,
          appointmentType: formValue.serviceAppointmentType,
          source: formValue.source,
          appointmentStartDate: formValue.appointmentDate ? dayjs(formValue.appointmentDate) : null,
          appointmentEndDate: formValue.appointmentEndDate ? dayjs(formValue.appointmentEndDate) : null,
          customerFirstName: formValue.customer.firstName,
          customerLastName: formValue.customer.lastName,
          customerEmail: formValue.customer.email,
          address: formValue.customerAddress.address1,
          city: formValue.customerAddress.city,
          zipCode: formValue.customerAddress.zipCode,
          telephone: formValue.customerAddress.telephone,
          latitude: formValue.geoCordinates.latitude,
          longitude: formValue.geoCordinates.longitude,
          teamId: formValue.team.id
        }
        form.setFieldsValue(value);
        if(formValue.files)
        {
          setFiles(formValue.files);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      
      const appFiles = uploadedFiles.filter(a => a.externalId === props.formValue?.externalId);

      if (appFiles && appFiles.length > 0) 
      {
        appFiles.push(...files);
        setFiles(appFiles);
      }
      dispatch(setUploadedFiles([]));
    }
  }, [uploadedFiles]);

  const submitForm = async () => {
    try {
      form.submit();
      await form.validateFields();
    } catch (errors) {
      return;
    }
    const param = form.getFieldsValue();
    param.files = files;
    dispatch<any>(updateAppointment(param));
  }

  const onTeamChange = async (teamid: any) => {
    const team = teams.filter(a => a.id === teamid);
    if (team && team[0]) {
      const value: any =
      {
        source: team[0].source
      }

      form.setFieldsValue(value);
    }
  }

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const uploadProps: UploadProps = {
    accept: ".pdf",
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleUpload = () => {
    if(props.formValue?.externalId) {
      const container = props.formValue?.externalId;
      dispatch<any>(uploadAppointmentFile(container, fileList[0]));
      setFileList([]);
    }
  };
  const onItemRemove = (file: UploadedFile) => {
    const appFiles = files.filter(a => a.url !== file.url);
    if(appFiles && appFiles.length > 0){
      setFiles(appFiles);
    }else{
      setFiles([]);
    }
  };
  
  return (
    <>
      <Modal
        title={'Edit Appointment'}
        open={show}
        onCancel={toggleShow}
        width={1000}

        footer={[
          <Button key='back' onClick={toggleShow}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={submitForm}
            style={{ marginLeft: 5 }}
          >
            Submit
          </Button>
        ]}
      >
        <Spin spinning={loading}>
          <Form form={form} labelCol={{
            span: 10,
          }}
            wrapperCol={{
              span: 16,
            }}>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Form.Item hidden label="Id" name="id" rules={[{ required: true }]}>
                <Input type="hidden" />
              </Form.Item>
              <Col span={12}>
                <Form.Item label="Order Number" name="orderNo" rules={[{ required: true }]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Work Order Type" name="workOrderType" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    allowClear
                    placeholder='Select Worktype'
                    optionFilterProp='children'
                    disabled
                  >
                    {appointmentTypes.map((g) => (
                      <Option key={g.id}>
                        {g.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Source" name="source" rules={[{ required: true }]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Appointment Type" name="appointmentType" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Appointment Start Date" name="appointmentStartDate" rules={[{ required: true }]}>
                  <DatePicker showTime />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Appointment End Date" name="appointmentEndDate" rules={[{ required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value < getFieldValue("appointmentStartDate")) {
                      return Promise.reject("End Date must be greater than start");
                    } else {
                      return Promise.resolve();
                    }
                  }
                })]}>
                  <DatePicker showTime />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Customer First Name" name="customerFirstName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Customer Last Name" name="customerLastName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Customer Email" name="customerEmail" rules={[{ required: true, type: 'email' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Team Id" name="teamId" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    allowClear
                    placeholder='Select Team'
                    optionFilterProp='children'
                    onChange={e => onTeamChange(e)}
                  >
                    {teams.map((g) => (
                      <Option key={g.id}>
                        {g.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Address" name="address" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Zip Code" name="zipCode" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="City" name="city" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Telephone" name="telephone" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Latitude" name="latitude" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Longitude" name="longitude" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <List
                  size="small"
                  header={<div>Files</div>}
                  bordered
                  dataSource={files}
                  renderItem={(item) =>
                    <List.Item>
                      <span style={{color: item.isNew ? "#bd8f08" : "black"}}> 
                      {item.originalFileName} </span>
                      <Button
                        danger
                        style={{
                          marginLeft: 8,
                        }}
                        shape='circle'
                        icon={<CloseOutlined />}
                        onClick={e => onItemRemove(item)}
                        size='small'
                      /></List.Item>}
                />
              </Col>
              <Col span={12}>
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={fileList.length === 0}
                  loading={loading}
                  style={{ marginTop: 16 }}
                >
                  Start Upload
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};