
export interface Config {
    url: string;
    bulkUploadFileTemplateUrl: string;
}

const apiConfig: Config = {
    url: document.location.origin.includes('.enpal.io') ?  `https://fulfilment-api-dynamo.craftos.enpal.io/api/v1.0` : `https://localhost:5001/api/v1.0`,
    bulkUploadFileTemplateUrl: 'https://enpalcrftfmtdynapiwest.blob.core.windows.net/uploadtemplate/appointment_upload_template_9fa84578-7844-4820-b483-f2987be8e6bf.xltx'
};

export {apiConfig};