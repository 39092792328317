import { AppointmentQuery } from '../models/appointment';
import { AppointmentEditDto } from '../models/appointmentEditDto';
import { ApiActions, ApiEventType } from '../models/event';
import { TableParams } from '../models/tabeParams';
import { setAppointmentTypes, setAppointments, setTableParams, setTeams, setUploadedFiles, } from '../store/reducers/appointmentReducer';
import {
  setEvent,
  setLoading,
} from '../store/reducers/sharedReducer';
import { UploadedFile } from '../store/state.model';
import { apiService } from './apiService';

export const getAppointments = (query: AppointmentQuery, onLoad: boolean) => async (dispatch: any) => {
  try {
    onLoad && dispatch(setLoading(true));

    var queryStr = Object.entries(query).map(([key, val]) => (val !== undefined || val !== null) ? `${key}=${val}` : `${key}=`).join('&');

    await apiService.getAppointments(queryStr).then(async (res) => {
      const pagination = JSON.parse(res.headers.get('X-Pagination'));

      const tableParam: TableParams =
      {
        pagination:
        {
          current: pagination?.CurrentPage ?? 1,
          pageSize: pagination?.PageSize ?? 15,
          total: pagination?.TotalCount ?? 0
        }
      }
      const apps = await res.json();
      dispatch(setTableParams(tableParam));
      dispatch(setAppointments(apps));
    });
  }catch(e: any){
    setEvent({
      type: ApiEventType.Error,
      action: ApiActions.OnError,
      message: "An error occurred"
    })
  }
   finally {
    onLoad && dispatch(setLoading(false));
  }
};

export const uploadAppointments = (file: any) => async (dispatch: any) => {
  try {

    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", file);

    await apiService.uploadAppointments(formData).then(async (res) => {

      res.blob().then((blob: any) => {
        dispatch(setEvent({
          type: ApiEventType.Success,
          action: ApiActions.AppointmentUploaded,
          message: `Upload completed. View the downloaded response for more information.`
        }));

        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'Upload result.xlsx');
        document.body.appendChild(link);
        link.click();

      })

    });
  } catch(e: any){
    setEvent({
      type: ApiEventType.Error,
      action: ApiActions.OnError,
      message: "An error occurred"
    })
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateAppointment = (request: AppointmentEditDto) => async (dispatch: any) => {
  try {

    dispatch(setLoading(true));
    await apiService.updateAppointment(request.id, request).then(async (res) => {
      if(res.errors)
      {
        dispatch(setEvent({
          type: ApiEventType.Error,
          action: ApiActions.OnError,
          message: res.errors
        }));
      }
      else
      {
        dispatch(setEvent({
          type: ApiEventType.Success,
          action: ApiActions.AppointmentUpdated,
          message: `Appointment updated successfully`
        }));
      }

    }).catch(e => { 
      dispatch(setEvent({
        type: ApiEventType.Error,
        action: ApiActions.OnError,
        message: `An error occurred`
      }));
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const getTeams = () => async (dispatch: any) => {

  await apiService.getTeams().then(async (res) => {

    dispatch(setTeams(res));

  }).catch(e => { 
    dispatch(setEvent({
      type: ApiEventType.Error,
      action: ApiActions.OnError,
      message: `An error occurred`
    }));
  });
};

export const getAppointmentTypes = () => async (dispatch: any) => {

  await apiService.getAppointmentTypes().then(async (res) => {

    dispatch(setAppointmentTypes(res));

  }).catch(e => { 
    dispatch(setEvent({
      type: ApiEventType.Error,
      action: ApiActions.OnError,
      message: `An error occurred`
    }));
  });
};

export const deleteAppointment = (id: string) => async (dispatch: any) => {
  try {

    dispatch(setLoading(true));
    await apiService.deleteAppointment(id).then(async (res) => {
      dispatch(setEvent({
        type: ApiEventType.Success,
        action: ApiActions.AppointmentDeleted,
        message: `Appointment deleted successfully`
      }));
    }).catch(a => {
      dispatch(setEvent({
        type: ApiEventType.Error,
        action: ApiActions.OnError,
        message: `An error occurred`
      }));
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const uploadAppointmentFile = (appId: string, file: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", file);

    await apiService.uploadFile(appId, formData).then(async (res) => {
      console.log(res);
      dispatch(setUploadedFiles([]));
      if(res && res.length > 0)
      {
        let files = res.map((x: any)=>{
         return <UploadedFile>
          {
               externalId: appId,
               fileName: x.fileName,
               originalFileName: x.originalFileName,
               url: x.url,
               isNew: true
           };
        });
        dispatch(setUploadedFiles(files));
      }
    });
  } catch(e: any){
    dispatch(setEvent({
      type: ApiEventType.Error,
      action: ApiActions.OnError,
      message: "An error occurred while uploading file"
    }));
    
  } finally {
    dispatch(setLoading(false));
  }
};